import {NEW_GAME_NAME_STAR_STATE_CHANGE} from '../actions/types'

const initialState = {
    newGameNameStarState: false
};

function marketingReducer (state = initialState, action) {
    switch (action.type) {
      case NEW_GAME_NAME_STAR_STATE_CHANGE:
         return Object.assign({}, state, {newGameNameStarState: action.newGameNameStarState})
      default:
        return state
    }
}

export default marketingReducer