import { createStore } from 'redux'
import gameReducer from './reducers/gameReducer'
import cloudbuildReducer from './reducers/cloudbuildReducer'
import marketingReducer from './reducers/marketingReducer'
import katmanReducer from './reducers/katmanReducer'
import liveOpsReducer from './reducers/liveOpsReducer'
import clientRemoteReducer from './reducers/clientRemoteReducer'
import { combineReducers } from 'redux'


const allReducers = combineReducers({
  gameReducer,
  cloudbuildReducer,
  marketingReducer,
  katmanReducer,
  liveOpsReducer,
  clientRemoteReducer
})

const store = createStore(allReducers)
export default store

