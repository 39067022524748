export const AUTH_STATE_CHANGE = 'AUTH_STATE_CHANGE'
export const NEW_GAME_STATE_CHANGE = 'NEW_GAME_STATE_CHANGE'
export const NEW_PUBLISHER_STATE_CHANGE = 'NEW_PUBLISHER_STATE_CHANGE'
export const NEW_UNITY_STATE_CHANGE = 'NEW_UNITY_STATE_CHANGE'
export const NEW_IOS_TEAM_STATE_CHANGE = 'NEW_IOS_TEAM_STATE_CHANGE'
export const NEW_GAME_NAME_STAR_STATE_CHANGE = 'NEW_GAME_NAME_STAR_STATE_CHANGE'
export const NEW_KATMAN_STATE_CHANGE = 'NEW_KATMAN_STATE_CHANGE'
export const NEW_LIVEOPS_STATE_CHANGE = 'NEW_LIVEOPS_STATE_CHANGE'
export const NEW_SEGMENT_STATE_CHANGE = 'NEW_SEGMENT_STATE_CHANGE'
export const NEW_PERMISSION_STATE_CHANGE = 'NEW_PERMISSION_STATE_CHANGE'
export const DASHBOARD_PANEL_STATE_CHANGE = 'DASHBOARD_PANEL_STATE_CHANGE'
export const LIVE_OPS_PANEL_STATE_CHANGE = 'LIVE_OPS_PANEL_STATE_CHANGE'
export const COMMIT_DESC_PANEL_STATE_CHANGE = "COMMIT_DESC_PANEL_STATE_CHANGE";

export const setAuthState = state => {
return { type: AUTH_STATE_CHANGE, authState: state }
}

export const setNewGameState = state => {
    return { type: NEW_GAME_STATE_CHANGE, newGameState: state }
}

export const setNewPublisherState = state => {
    return { type: NEW_PUBLISHER_STATE_CHANGE, newPublisherState: state }
}

export const setNewIosTeamState = state => {
    return { type: NEW_IOS_TEAM_STATE_CHANGE, newIosTeamState: state }
}

export const setNewUnityState = state => {
    return { type: NEW_UNITY_STATE_CHANGE, newUnityState: state }
}

export const setNewGameNameStarState = state => {
    return { type: NEW_GAME_NAME_STAR_STATE_CHANGE, newGameNameStarState: state }
}

export const setNewKatmanState = state => {
    return { type: NEW_KATMAN_STATE_CHANGE, newKatmanState: state }
}

export const setNewLiveOpsTemplateState = state => {
    return { type: NEW_LIVEOPS_STATE_CHANGE, newLiveOpsTemplateState: state }
}

export const setNewSegmentState = state => {
    return { type: NEW_SEGMENT_STATE_CHANGE, newSegmentState: state }
}

export const setNewPermissionState = state => {
    return { type: NEW_PERMISSION_STATE_CHANGE, newPermissionState: state }
}

export const setDashboardPanelState = state => {
    return { type: DASHBOARD_PANEL_STATE_CHANGE, dashboardPanelState: state }
}

export const setLiveOpsPanelState = state => {
    return { type: LIVE_OPS_PANEL_STATE_CHANGE, liveOpsPanelState: state }
}

export const setCommitDescPanelState = state => {
    return { type: COMMIT_DESC_PANEL_STATE_CHANGE, commitDescPanelState: state }
}