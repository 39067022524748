export const logo = ['608 134', `
  <title>Magiclab</title>
  <g transform="translate(0.000000,128.000000) scale(0.200000,-0.200000)"
fill="#000000" stroke="none">
<path d="M348 613 c-31 -35 -122 -190 -159 -268 -36 -77 -83 -224 -74 -233 2
-2 26 15 54 38 50 42 53 49 67 140 9 64 60 204 100 278 35 65 38 74 12 45z"/>
<path d="M364 533 c3 -10 10 -40 16 -68 14 -67 59 -192 73 -203 7 -6 41 -13
77 -16 l65 -7 -40 19 c-23 11 -53 34 -69 52 -36 43 -82 136 -97 194 -6 25 -16
46 -21 46 -5 0 -7 -8 -4 -17z"/>
<path d="M767 383 c-6 -10 -47 -147 -47 -157 0 -3 13 -6 28 -6 26 0 29 4 38
48 l9 47 27 -46 c42 -72 62 -70 104 11 l18 35 9 -35 c5 -19 11 -41 13 -47 2
-7 15 -13 29 -13 25 0 26 2 20 33 -31 144 -62 169 -110 87 -16 -27 -32 -50
-35 -50 -3 0 -18 23 -33 50 -26 46 -57 65 -70 43z"/>
<path d="M1144 368 c-28 -32 -104 -136 -104 -143 0 -3 13 -5 29 -5 24 0 37 10
67 51 l38 52 23 -29 23 -29 -38 -3 c-24 -2 -42 -10 -50 -22 -12 -19 -9 -20 83
-20 52 0 95 2 95 5 0 8 -103 149 -115 157 -17 13 -31 9 -51 -14z"/>
<path d="M1380 383 c-63 -23 -78 -87 -31 -134 28 -27 34 -29 110 -29 l81 0 0
50 0 50 -75 0 c-73 0 -75 -1 -65 -20 8 -15 21 -20 50 -20 22 0 40 -4 40 -10 0
-17 -87 -12 -104 6 -38 37 1 74 79 74 39 0 53 4 63 20 12 19 10 20 -60 19 -40
0 -80 -3 -88 -6z"/>
<path d="M1560 305 c0 -84 0 -85 25 -85 25 0 25 1 25 85 0 84 0 85 -25 85 -25
0 -25 -1 -25 -85z"/>
<path d="M1675 378 c-41 -22 -56 -67 -39 -113 11 -27 60 -45 123 -45 50 0 62
3 71 20 10 19 8 20 -50 20 -74 0 -100 12 -100 46 0 31 38 47 87 38 27 -5 39
-2 59 17 l24 22 0 -81 0 -82 73 0 c59 0 77 4 90 18 10 10 17 20 17 23 0 2 -29
2 -65 1 l-65 -4 0 66 0 66 -102 0 c-57 0 -112 -6 -123 -12z"/>
<path d="M2154 382 c-10 -7 -124 -158 -124 -165 0 -2 16 -1 34 1 29 3 41 13
68 53 18 27 36 49 39 49 3 0 15 -13 27 -30 l23 -30 -40 0 c-30 0 -43 -5 -51
-20 -10 -19 -8 -20 85 -20 74 0 94 3 89 13 -19 30 -79 114 -98 135 -20 23 -34
27 -52 14z"/>
<path d="M2347 372 c-10 -10 -17 -21 -17 -23 0 -2 36 -3 80 -1 70 4 80 2 80
-13 0 -14 -11 -16 -82 -14 l-83 2 0 -51 -1 -52 91 0 c119 0 135 10 130 83 -1
28 -3 55 -4 58 -6 32 -167 41 -194 11z m153 -102 c0 -6 -28 -10 -65 -10 -37 0
-65 4 -65 10 0 6 28 10 65 10 37 0 65 -4 65 -10z"/>
<path d="M565 204 c-111 -17 -240 -54 -365 -104 -155 -62 -190 -79 -190 -91 0
-8 4 -8 13 -1 22 19 181 60 294 77 63 10 150 15 210 13 l103 -3 23 35 c13 19
32 47 42 63 l17 27 -28 -1 c-16 -1 -69 -7 -119 -15z m75 -31 c0 -3 -7 -15 -17
-25 -13 -15 -31 -18 -100 -18 -56 0 -83 4 -83 12 0 6 3 9 6 5 3 -3 18 -1 33 5
49 20 161 35 161 21z"/>
</g>
`]
