import {
    NEW_LIVEOPS_STATE_CHANGE, 
    NEW_SEGMENT_STATE_CHANGE,
    LIVE_OPS_PANEL_STATE_CHANGE
} from '../actions/types'

const initialState = {
    newLiveOpsTemplateState: false,
    newSegmentState: false,
    liveOpsPanelState: false
};

function liveOpsReducer (state = initialState, action) {
    switch (action.type) {
      case NEW_LIVEOPS_STATE_CHANGE:
         return Object.assign({}, state, {newLiveOpsTemplateState: action.newLiveOpsTemplateState})
      case NEW_SEGMENT_STATE_CHANGE:
         return Object.assign({}, state, {newSegmentState: action.newSegmentState})
    case LIVE_OPS_PANEL_STATE_CHANGE:
         return Object.assign({}, state, {liveOpsPanelState: action.liveOpsPanelState})
           
      default:
        return state
    }
}

export default liveOpsReducer