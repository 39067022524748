import {NEW_GAME_STATE_CHANGE, NEW_PUBLISHER_STATE_CHANGE, NEW_UNITY_STATE_CHANGE, NEW_PERMISSION_STATE_CHANGE, DASHBOARD_PANEL_STATE_CHANGE, LIVE_OPS_PANEL_STATE_CHANGE} from '../actions/types'

const initialState = {
  newGameState: false,
  newGamePermissionState: false,
  newPublisherState: false,
  newUnityState: false,
  dashboardPanelState: false,
  liveOpsPanelState: false
};

function gameReducer (state = initialState, action) {
    switch (action.type) {
      case NEW_GAME_STATE_CHANGE:
         return Object.assign({}, state, {newGameState: action.newGameState})
      case NEW_PERMISSION_STATE_CHANGE:
         return Object.assign({}, state, {newPermissionState: action.newPermissionState})
      case NEW_PUBLISHER_STATE_CHANGE:
         return Object.assign({}, state, {newPublisherState: action.newPublisherState})
      case NEW_UNITY_STATE_CHANGE:
        return Object.assign({}, state, {newUnityState: action.newUnityState})
      case DASHBOARD_PANEL_STATE_CHANGE:
        return Object.assign({}, state, {dashboardPanelState: action.dashboardPanelState})
      case LIVE_OPS_PANEL_STATE_CHANGE:
        return Object.assign({}, state, {liveOpsPanelState: action.liveOpsPanelState})
      default:
        return state
    }
}

export default gameReducer