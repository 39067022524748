import {COMMIT_DESC_PANEL_STATE_CHANGE} from '../actions/types'

const initialState = {
    commitDescPanelState: false
};

function clientRemoteReducer (state = initialState, action) {
    switch (action.type) {
      case COMMIT_DESC_PANEL_STATE_CHANGE:
         return Object.assign({}, state, {commitDescPanelState: action.commitDescPanelState})
      default:
        return state
    }
}

export default clientRemoteReducer