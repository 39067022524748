import {NEW_KATMAN_STATE_CHANGE} from '../actions/types'

const initialState = {
    newKatmanState: false
};

function katmanReducer (state = initialState, action) {
    switch (action.type) {
      case NEW_KATMAN_STATE_CHANGE:
         return Object.assign({}, state, {newKatmanState: action.newKatmanState})
      default:
        return state
    }
}

export default katmanReducer