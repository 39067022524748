import {NEW_IOS_TEAM_STATE_CHANGE} from '../actions/types'

const initialState = {
  newIosTeamState: false
};

function cloudbuildReducer (state = initialState, action) {
    switch (action.type) {
      case NEW_IOS_TEAM_STATE_CHANGE:
         return Object.assign({}, state, {newIosTeamState: action.newIosTeamState})
      default:
        return state
    }
}

export default cloudbuildReducer